<template>
  <div v-if="adParams.adClient && adParams.adSlot" class="in-feed" :class="unfilled">
    <!-- The ad -->
    <ins
      ref="googleIns"
      class="adsbygoogle"
      style="display: block"
      data-ad-format="fluid"
      :data-ad-client="adParams.adClient"
      :data-ad-slot="adParams.adSlot"
      :data-ad-layout-key="adParams.layoutKey"
    />
    <div class="title">
      <BaseText class="light-70">{{ $t("app.advertisement") }}</BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
import { InFeedAdDisplayAt } from "~/types";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    adsbygoogle: any;
  }
}

const props = defineProps<{
  displayAt: InFeedAdDisplayAt;
  adIndex: number;
}>();

defineEmits<{
  (e: "update-ad-status", status: "filled" | "unfilled"): void;
}>();

const host = useCurrentHost();

onMounted(async () => {
  // Wait for the <ins> element to be rendered to the dom
  await nextTick();
  if (window.adsbygoogle) {
    try {
      window.adsbygoogle.push({});
    } catch (e) {
      console.error("IN FEED", e);
    }
  } else {
    try {
      window.adsbygoogle = [{}];
    } catch (e) {
      console.error("IN FEED", e);
    }
  }

  // Check if we have googlefc on the window 500ms after loading. If we don't we can assume the use of an ad blocker.
  setTimeout(() => {
    if (!window.googlefc) {
      unfilled.value = true;
    }
  }, 500);
});

/*
 * Use a mutation observer to detect changes in the ad status, if the ad is unfilled, emit that so we can load a fallback ad from our own backend
 */
const hasLogged = ref(false);
const googleIns = ref<HTMLElement>();
const unfilled = ref(false);
useMutationObserver(
  googleIns,
  () => {
    if (!googleIns.value) return;
    const adStatus = googleIns.value?.dataset.adStatus;

    if (adStatus === "filled" || adStatus === "unfilled") {
      unfilled.value = adStatus === "unfilled";
    }

    if (!hasLogged.value && adStatus === "filled") {
      event("ad_show", {
        type: "adsense",
      });
      hasLogged.value = true;
    }
  },
  {
    attributes: true,
    attributeFilter: ["data-ad-status"],
  }
);

const isVisible = useElementVisibility(googleIns);

// Log the impression when the ad is visible
watch(
  isVisible,
  (isVisible) => {
    if (isVisible) {
      // Log to Google Analytics only if the ad is filled
      if (googleIns.value?.dataset.adStatus === "filled" && !hasLogged.value) {
        event("ad_show", {
          type: "adsense",
        });
        hasLogged.value = true;
      }
    }
  },
  { immediate: true }
);

const adParams = computed(() => {
  const adData = adSlots[host.value];
  const adClient = adData.adClient;
  const adsLength = adData.adSlots[props.displayAt].length;
  const ad = adData.adSlots[props.displayAt][props.adIndex % adsLength];

  return { adClient, ...ad };
});

interface AdParams {
  adSlot: string;
  layoutKey: string;
}

interface AdClient {
  adClient: string;
  adSlots: {
    [key in InFeedAdDisplayAt]: AdParams[];
  };
}

type AdSlots = {
  allefolders: AdClient;
  promopromo: AdClient;
};

const adSlots: AdSlots = {
  allefolders: {
    adClient: "ca-pub-7814118610095655",
    adSlots: {
      [InFeedAdDisplayAt.BrochuresGrid]: [
        { layoutKey: "+1v+sa-1a-5c+ee", adSlot: "7986088880" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "5332332357" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "9000104881" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "7060337256" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "3256823610" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "4729697125" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "5795366786" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "7164288771" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "2243124844" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "9111785524" },
      ],
      [InFeedAdDisplayAt.OffersGrid]: [
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "5799226477" },
        { layoutKey: "+1x+s9-1r-45+eb", adSlot: "7112308141" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "6533733386" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "4294573112" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "2594488373" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "1668409776" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "7655243368" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "7192208930" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "6781145392" },
        { layoutKey: "+2p+sa-1g-4q+e1", adSlot: "2789919758" },
      ],
    },
  },
  promopromo: {
    adClient: "ca-pub-9832024765354406",
    adSlots: {
      // TODO: Get actual list of ad slots from client
      [InFeedAdDisplayAt.BrochuresGrid]: [
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "6127031519" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "3283216064" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "2480436133" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "5522366591" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "6071191195" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "7956958245" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "4404726047" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "6452304881" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "3253456167" },
        { layoutKey: "+1s+s9-1r-45+eb", adSlot: "4213154353" },
      ],
      [InFeedAdDisplayAt.OffersGrid]: [
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "5334664330" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "9082337654" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "2424595815" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "9106388383" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "5686018125" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "6482254735" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "7703132796" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "4181364768" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "2349408345" },
        { layoutKey: "-6v+f1-1g-4q+e0", adSlot: "6234842720" },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
.in-feed {
  width: 100%;
  height: 100%;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;

  .adsbygoogle {
    flex: 1;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25rem;
    width: 100%;

    .text {
      padding: 0 1rem;
    }
  }
}
</style>
